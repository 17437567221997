<template>
  <StDrawerHeader class="instruction-title" :title="title" with-border-bottom />
  <section class="instruction-content" data-t="m-web-push-instruction-modal">
    <div
      v-for="(item, index) in instructionList"
      :key="index"
      class="instruction-item"
    >
      <p>{{ item.text }}</p>
      <img :src="item.image" />
    </div>
  </section>
</template>

<script setup lang="ts">
import type { UserAgentOS } from '@st/utils/lib/parseUserAgent/types'
import IosEn1 from './assets/notifications_ios_step_1_en.jpg'
import IosEs1 from './assets/notifications_ios_step_1_es.jpg'
import IosPt1 from './assets/notifications_ios_step_1_pt.jpg'
import IosRu1 from './assets/notifications_ios_step_1_ru.jpg'
import IosEn2 from './assets/notifications_ios_step_2_en.jpg'
import IosEs2 from './assets/notifications_ios_step_2_es.jpg'
import IosPt2 from './assets/notifications_ios_step_2_pt.jpg'
import IosRu2 from './assets/notifications_ios_step_2_ru.jpg'
import IosEn3 from './assets/notifications_ios_step_3_en.jpg'
import IosEs3 from './assets/notifications_ios_step_3_es.jpg'
import IosPt3 from './assets/notifications_ios_step_3_pt.jpg'
import IosRu3 from './assets/notifications_ios_step_3_ru.jpg'
import IosEn4 from './assets/notifications_ios_step_4_en.jpg'
import IosEs4 from './assets/notifications_ios_step_4_es.jpg'
import IosPt4 from './assets/notifications_ios_step_4_pt.jpg'
import IosRu4 from './assets/notifications_ios_step_4_ru.jpg'
import AndroidEn1 from './assets/notifications_android_step_1_en.jpg'
import AndroidEs1 from './assets/notifications_android_step_1_es.jpg'
import AndroidPt1 from './assets/notifications_android_step_1_pt.jpg'
import AndroidRu1 from './assets/notifications_android_step_1_ru.jpg'
import AndroidEn2 from './assets/notifications_android_step_2_en.jpg'
import AndroidEs2 from './assets/notifications_android_step_2_es.jpg'
import AndroidPt2 from './assets/notifications_android_step_2_pt.jpg'
import AndroidRu2 from './assets/notifications_android_step_2_ru.jpg'
import AndroidEn3 from './assets/notifications_android_step_3_en.jpg'
import AndroidEs3 from './assets/notifications_android_step_3_es.jpg'
import AndroidPt3 from './assets/notifications_android_step_3_pt.jpg'
import AndroidRu3 from './assets/notifications_android_step_3_ru.jpg'

const { os } = defineProps<{
  os: UserAgentOS
}>()
const { t, locale } = useI18n()

const iosImages: Record<string, string>[] = [
  {
    en: IosEn1,
    es: IosEs1,
    pt: IosPt1,
    ru: IosRu1,
  },
  {
    en: IosEn2,
    es: IosEs2,
    pt: IosPt2,
    ru: IosRu2,
  },
  {
    en: IosEn3,
    es: IosEs3,
    pt: IosPt3,
    ru: IosRu3,
  },
  {
    en: IosEn4,
    es: IosEs4,
    pt: IosPt4,
    ru: IosRu4,
  },
]

const androidImages: Record<string, string>[] = [
  {
    en: AndroidEn1,
    es: AndroidEs1,
    pt: AndroidPt1,
    ru: AndroidRu1,
  },
  {
    en: AndroidEn2,
    es: AndroidEs2,
    pt: AndroidPt2,
    ru: AndroidRu2,
  },
  {
    en: AndroidEn3,
    es: AndroidEs3,
    pt: AndroidPt3,
    ru: AndroidRu3,
  },
]

const title = computed(() =>
  os === 'ios'
    ? t('webPush.instruction.mobile.titleIos')
    : t('webPush.instruction.mobile.titleAndroid'),
)

const iosInstruction = [
  {
    text: t('webPush.instruction.mobile.textIos1'),
    image: iosImages[0][locale.value],
  },
  {
    text: t('webPush.instruction.mobile.textIos2'),
    image: iosImages[1][locale.value],
  },
  {
    text: t('webPush.instruction.mobile.textIos3'),
    image: iosImages[2][locale.value],
  },
  {
    text: t('webPush.instruction.mobile.textIos4'),
    image: iosImages[3][locale.value],
  },
]
const androidInstruction = [
  {
    text: t('webPush.instruction.mobile.textAndroid1'),
    image: androidImages[0][locale.value],
  },
  {
    text: t('webPush.instruction.mobile.textAndroid2'),
    image: androidImages[1][locale.value],
  },
  {
    text: t('webPush.instruction.mobile.textAndroid3'),
    image: androidImages[2][locale.value],
  },
]

const instructionList = os === 'ios' ? iosInstruction : androidInstruction
</script>

<style scoped>
.instruction-title {
  margin-top: var(--spacing-200);
}

.instruction-content {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-250);

  padding: var(--spacing-100) var(--spacing-200) var(--spacing-200);
}

.instruction-item {
  p {
    margin: 0;
    margin-bottom: var(--spacing-100);
    font: var(--mobile-text-content-regular);
    color: var(--text-secondary);
  }

  img {
    display: block;

    width: 100%;
    margin-top: var(--spacing-100);

    object-fit: cover;
    border-radius: var(--border-radius-150);
  }
}
</style>
